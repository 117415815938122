import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-hide-scope-of-advice"
export default class extends Controller {
  static targets = ["scope", "inScope", "outOfScope"]

  connect() {
    console.log("ShowHideScopeOfAdvice controller connected!");

    this.handleScopeChange();
    this.element.addEventListener("change", () => this.handleScopeChange());
  }

  handleScopeChange() {
    const selectedValue = this.scopeTarget.value;
    console.log("Selected value: ", selectedValue);

    if (selectedValue === "in_scope") {
      this.inScopeTarget.classList.remove("hidden");
      this.outOfScopeTarget.classList.add("hidden");
    } else if (selectedValue === "out_of_scope") {
      this.inScopeTarget.classList.add("hidden");
      this.outOfScopeTarget.classList.remove("hidden");
    } else {
      this.inScopeTarget.classList.add("hidden");
      this.outOfScopeTarget.classList.add("hidden");
    }
  }
}

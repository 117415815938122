import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-hide-risk-profile"
export default class extends Controller {
  static targets = ["was_the_assessed_risk_profile_agreed_to_by_the_client", "AgreedRiskProfile","riskReasonLabel"]

  connect() {
    this.handleRiskProfileChange();
    this.element.addEventListener("change", () => this.handleRiskProfileChange());
  }

  handleRiskProfileChange() {
    const isRiskProfileAgreedValue = this.was_the_assessed_risk_profile_agreed_to_by_the_clientTarget.value;

    // Perform logic based on the checkbox values
    if (isRiskProfileAgreedValue === 'false') {
      // Show AgreedRiskProfile
      this.AgreedRiskProfileTarget.classList.remove("hidden");
      this.riskReasonLabelTarget.innerText = "Reason why the Agreed Risk Profile is suitable for the client";
    } else if (isRiskProfileAgreedValue === 'true') {
      // Hide AgreedRiskProfile
      this.AgreedRiskProfileTarget.classList.add("hidden");
      this.riskReasonLabelTarget.innerText = "Reason why the Assessed Risk Profile is suitable for the client";
    } else {
      // Hide AgreedRiskProfile
      this.AgreedRiskProfileTarget.classList.add("hidden");
      this.riskReasonLabelTarget.innerText = "Reason why the Assessed Risk Profile is suitable for the client";
    }
  }
}

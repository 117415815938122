function initializePagination() {
  let pagy_nav = document.querySelector('.pagy-nav');

  // let pages = pagy_nav.querySelector('span.pages');
  let currentActiveNav = pagy_nav.querySelector('.pagy-nav .page.active');
  let prevActiveNav = currentActiveNav.previousElementSibling
  let nextActiveNav = currentActiveNav.nextElementSibling

  // initialize the pagination
  pagy_nav.style.display = 'inline-flex';
  pagy_nav.style.isolation = 'isolate';

  if(!prevActiveNav.classList.contains('prev') ) {
    prevActiveNav.classList.add('prev-active');
  }

  if(!nextActiveNav.classList.contains('next') ) {
    nextActiveNav.classList.add('next-active');
  }
}

// Listen for both DOMContentLoaded and turbo:render events
document.addEventListener('DOMContentLoaded', initializePagination);
document.addEventListener('turbo:render', initializePagination);
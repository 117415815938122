document.addEventListener("turbo:load", function() {
  // Select all <a> elements within .trix-content
  const links = document.querySelectorAll('.trix-content a');

  // Loop through the NodeList object and set each link's target attribute to '_blank'
  links.forEach(function(link) {
    link.setAttribute('target', '_blank');
    // Add rel="noopener noreferrer" for security and performance reasons
    link.setAttribute('rel', 'noopener noreferrer');
  });
});

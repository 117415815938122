import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-hide-terms-of-engagement"
export default class extends Controller {
  static targets = ["engagementLetterRequired", "engagementLetterDetails"]

  connect() {
    console.log("ShowHideTermsOfEngagement controller connected!");  

    this.handleEngagementLetterRequiredChange();
    this.element.addEventListener("change", () => this.handleEngagementLetterRequiredChange());
  }

  handleEngagementLetterRequiredChange() {
    const engagementLetterRequiredValue = this.engagementLetterRequiredTarget.value;
    console.log(`Engagement Letter Required: ${engagementLetterRequiredValue}`);

    // Perform logic based on the checkbox values
    if (engagementLetterRequiredValue === 'yes') {
      // Show engagement letter details
      this.engagementLetterDetailsTarget.classList.remove("hidden");
    } else {
      // Hide engagement letter details
      this.engagementLetterDetailsTarget.classList.add("hidden");
    }
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-hide-revised-term"
export default class extends Controller {
  static targets = ["offeredRevisedTerms", "revisedTermDetails"]

  connect() {
    console.log("ShowHideRevisedTerm connected!")

    this.handleRevisedTermChange();
    this.element.addEventListener("change", () => this.handleRevisedTermChange());
  }

  handleRevisedTermChange() {
    const revisedTermValue = this.offeredRevisedTermsTarget.value;

    console.log(`Offered Revised Terms: ${revisedTermValue}`);

    // Perform logic based on the checkbox values
    if (revisedTermValue === 'true') {
      // Show
      this.revisedTermDetailsTarget.classList.remove("hidden");
    } else {
      // Hide
      this.revisedTermDetailsTarget.classList.add("hidden");
    }
  }
}

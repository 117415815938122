import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-display-rules"
export default class extends Controller {
  static targets = ["beneficiarySelect", "beneficiariesEntitlement"];

  connect() {
    this.updateVisibility();
  }

  updateVisibility() {
    const selectedValue = this.beneficiarySelectTarget.value;
    console.log("Selected value:", selectedValue);

    const shouldShow =
      selectedValue !== "not_applicable" &&
      selectedValue !== ""; // Adjust according to your actual enum values
    console.log("Should show:", shouldShow);

    if (shouldShow) {
      this.beneficiariesEntitlementTarget.style.display = "block";
    } else {
      this.beneficiariesEntitlementTarget.style.display = "none";
    }
  }
}

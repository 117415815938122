import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "proceeding_with_advice",
    "ReasonForNotProceeding",
    "AuthorityToProceedDetails",
    "advice_document_expired",
    "ChangeInCircumstances"
  ]

  connect() {
    console.log("ShowHideAuthorityToProceed connected!")

    this.handleProceedingWithAdviceChange();
    this.handleAdviceDocumentExpiredChange();

    this.element.addEventListener("change", () => {
      this.handleProceedingWithAdviceChange();
      this.handleAdviceDocumentExpiredChange();
    });
  }

  handleProceedingWithAdviceChange() {
    const proceedingValue = this.proceeding_with_adviceTarget.value;

    console.log(`Proceeding with advice: ${proceedingValue}`);

    if (proceedingValue == 'true') { // 'true' corresponds to 'Yes' from the dropdown
      this.ReasonForNotProceedingTarget.classList.add("hidden");
      this.AuthorityToProceedDetailsTarget.classList.remove("hidden");
    } else if (proceedingValue == 'false') { // 'false' corresponds to 'No' from the dropdown
      this.ReasonForNotProceedingTarget.classList.remove("hidden");
      this.AuthorityToProceedDetailsTarget.classList.add("hidden");
    } else {
      this.ReasonForNotProceedingTarget.classList.add("hidden");
      this.AuthorityToProceedDetailsTarget.classList.add("hidden");
    }
  }

  handleAdviceDocumentExpiredChange() {
    const adviceDocumentExpiredValue = this.advice_document_expiredTarget.value;

    console.log(`Advice document expired: ${adviceDocumentExpiredValue}`);

    if (adviceDocumentExpiredValue == 'true') { // 'true' corresponds to 'Yes' from the dropdown
      this.ChangeInCircumstancesTarget.classList.remove("hidden");
    } else if (adviceDocumentExpiredValue == 'false') { // 'false' corresponds to 'No' from the dropdown
      this.ChangeInCircumstancesTarget.classList.add("hidden");
    } else {
      this.ChangeInCircumstancesTarget.classList.add("hidden");
    }
  }
}

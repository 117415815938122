import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="quick-text"
export default class extends Controller {
  static targets = ["button", "textarea"]

  connect() {
    // Store the original min-height when the controller connects
    this.originalMinHeight = this.textareaTarget.style.minHeight
  }

  generate(event) {
    event.preventDefault()

    const button = event.currentTarget
    const url = button.dataset.url
    const fieldTargetId = button.dataset.fieldtarget
    const textarea = document.getElementById(fieldTargetId)

    // Disable the button and show spinner
    button.disabled = true
    const icon = button.querySelector('.button-icon')
    const buttonText = button.querySelector('.button-text')
    icon.classList.remove('fa-file-pen')
    icon.classList.add('fa-sync', 'fa-spin', 'mr-2')
    buttonText.textContent = 'Generating Quick Text ...'

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'Accept': 'application/json'
      },
      credentials: 'same-origin'
    })
    .then(response => response.json())
    .then(data => {
      // Insert the generated text into the textarea
      textarea.value = data.quick_text

      // Adjust the height of the textarea
      this.adjustTextareaHeight(textarea)

      // Re-enable the button and reset spinner
      button.disabled = false
      icon.classList.remove('fa-sync', 'fa-spin', 'mr-2')
      icon.classList.add('fa-file-pen')
      buttonText.textContent = ''
    })
    .catch((error) => {
      console.error('Error:', error)
      // Re-enable the button and reset spinner
      button.disabled = false
      icon.classList.remove('fa-sync', 'fa-spin', 'mr-2')
      icon.classList.add('fa-file-pen')
      buttonText.textContent = ''
      alert('An error occurred while generating quick text.')
    })
  }

  adjustTextareaHeight(textarea) {
    textarea.style.height = 'auto';
    textarea.style.height = `${Math.max(textarea.scrollHeight + 20, 100)}px`;
  }
}
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-hide-advice-document"
export default class extends Controller {
  static targets = ["adviceDocumentInstructionRecommendationRequired",
                    "adviceDocumentInstructionRecommendationInstructions"]

  connect() {
    console.log("ShowHideAdviceDocumentInstructionRecommendation connected!")

    this.handleadviceDocumentInstructionRecommendationRequiredChange();
    this.element.addEventListener("change", () => this.handleadviceDocumentInstructionRecommendationRequiredChange());
  }

  handleadviceDocumentInstructionRecommendationRequiredChange() {
    const adviceDocumentInstructionRecommendationRequiredValue = this.adviceDocumentInstructionRecommendationRequiredTarget.value;

    console.log(`Advice Document Instructin Recommendation Required: ${adviceDocumentInstructionRecommendationRequiredValue}`);

    // Perform logic based on the adviceDocumentInstructionRecommendationRequiredValue 
    if (adviceDocumentInstructionRecommendationRequiredValue === 'yes') {
      // Show
      this.adviceDocumentInstructionRecommendationInstructionsTarget.classList.remove("hidden");
    } else {
      // Hide
      this.adviceDocumentInstructionRecommendationInstructionsTarget.classList.add("hidden");
    }
  }  
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-hide-product-comparison"
export default class extends Controller {
  static targets = ["ProductType",
                    "ProductProvider",
                    "InvestmentOptions",
                    "InsuranceOptions",
                    "TaskDetails"
                   ]

  connect() {
    console.log("ProductComparisonController connected!");

    this.handleProductTypeChange();
    this.element.addEventListener("change", () => this.handleProductTypeChange());
  }

  handleProductTypeChange() {
    const selectedValue = this.ProductTypeTarget.value;

    console.log(`ProductType: ${selectedValue}`);

    // Perform logic based on the selectedValue
    if (selectedValue === "superannuation" || selectedValue === "retirement" || selectedValue === "investment") {
      this.ProductProviderTarget.classList.remove("hidden");
      this.InvestmentOptionsTarget.classList.remove("hidden");
      this.InsuranceOptionsTarget.classList.add("hidden");
      this.TaskDetailsTarget.classList.remove("hidden");
    } else if (selectedValue === "insurance") {
      this.ProductProviderTarget.classList.remove("hidden");
      this.InvestmentOptionsTarget.classList.add("hidden");
      this.InsuranceOptionsTarget.classList.remove("hidden");
      this.TaskDetailsTarget.classList.remove("hidden");
    } else {
      this.ProductProviderTarget.classList.add("hidden");
      this.InvestmentOptionsTarget.classList.add("hidden");
      this.InsuranceOptionsTarget.classList.add("hidden");
      this.TaskDetailsTarget.classList.add("hidden");
    }
  }
}

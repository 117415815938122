import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-hide-dependent-fields"
export default class extends Controller {
  static targets = ["field"]
  
  connect() {
    console.log("ShowHideDependentFields connected!")

    this.fieldTargets.forEach(field => {
      const dependentOnFieldId = field.dataset.dependentOnFieldIdValue;
      if (dependentOnFieldId) {
        const controllingFieldWrapper = this.element.querySelector(`[data-field-id-value="${dependentOnFieldId}"]`);
        const controllingField = controllingFieldWrapper ? controllingFieldWrapper.querySelector('input, select') : null;
        if (controllingField) {
          controllingField.addEventListener('change', () => this.updateFieldVisibility(field, controllingField));
          this.updateFieldVisibility(field, controllingField); // Initial visibility
        }
      }
    });
  }

  updateFieldVisibility(field, controllingField) {
    const isVisible = controllingField.value === 'true';
    field.style.display = isVisible ? 'block' : 'none';
  }
}

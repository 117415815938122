export function toggleOptionsField(fieldTypeSelect, optionsField) {
  if (fieldTypeSelect.value === 'select') {
    optionsField.style.display = 'block';
  } else {
    optionsField.style.display = 'none';
  }
}

export function initializeConditionalFields() {
  const fieldTypeSelect = document.getElementById('field_type_select');
  const optionsField = document.getElementById('options_field');

  if (fieldTypeSelect && optionsField) {
    toggleOptionsField(fieldTypeSelect, optionsField);
    fieldTypeSelect.addEventListener('change', () => toggleOptionsField(fieldTypeSelect, optionsField));
  }
}

export function setupConditionalFields() {
  // Run on initial page load
  document.addEventListener('DOMContentLoaded', initializeConditionalFields);

  // Run after Turbo drive navigation
  document.addEventListener('turbo:load', initializeConditionalFields);

  // Run after Turbo frame updates
  document.addEventListener('turbo:frame-load', initializeConditionalFields);

  // Optionally, run after Turbo stream updates
  document.addEventListener('turbo:render', initializeConditionalFields);
}
function addDependentFieldListeners() {
  console.log("Custom form dependent fields script loaded");

  // Get all fields that have a dependent_on_field_id
  const dependentFields = document.querySelectorAll('[data-dependent-on]');
  console.log("Dependent fields:", dependentFields);

  dependentFields.forEach(function(field) {
    const parentFieldId = field.getAttribute('data-dependent-on');
    const parentField = document.querySelector(`#form-field-${parentFieldId} select`);

    if (parentField) {
      // Initial check when the page loads or reloads
      toggleFieldVisibility(parentField, field);

      // Add event listener to the parent field to toggle visibility of dependent fields
      parentField.addEventListener('change', function() {
        toggleFieldVisibility(parentField, field);
      });
    }
  });

  function toggleFieldVisibility(parentField, dependentField) {
    const parentValue = parentField.value;

    // Check if the value is "true" (Yes) and show/hide based on that
    if (parentValue === 'true') {
      dependentField.style.display = 'block';
    } else {
      dependentField.style.display = 'none';
    }
  }

  // Run the function once more after the page load to ensure it works after form submission errors
  runInitialVisibilityCheck();

  function runInitialVisibilityCheck() {
    dependentFields.forEach(function(field) {
      const parentFieldId = field.getAttribute('data-dependent-on');
      const parentField = document.querySelector(`#form-field-${parentFieldId} select`);

      if (parentField) {
        // Initial check when the page loads or reloads due to validation error
        toggleFieldVisibility(parentField, field);
      }
    });
  }
}

// Listen for both DOMContentLoaded and turbo:render events
document.addEventListener('DOMContentLoaded', addDependentFieldListeners);
document.addEventListener('turbo:render', addDependentFieldListeners);

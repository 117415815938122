import { Controller } from "@hotwired/stimulus"

// Define a new class that extends Stimulus Controller
// It's connected to data-controller="strategy" in the HTML
export default class extends Controller {
  // Declare the targets that this controller will connect to
  static targets = ["areaOfAdvice", "strategy", "strategySelect", "strategyTextField"]

  // This method is called when the controller is connected to the DOM
  connect() {
    console.log("Strategy Controller Connected!")

    if (this.areaOfAdviceTarget.value) {
      this.updateStrategies();
    }
  }

  // This method is responsible for updating the strategies select box
  updateStrategies() {
    // Get the selected Area of Advice's ID from the areaOfAdvice target
    const areaOfAdviceId = this.areaOfAdviceTarget.value

    // If no Area of Advice is selected, reset the strategy selection
    if (!areaOfAdviceId) {
      this.resetStrategySelection();
      return; // Exit the method early if there's no Area of Advice selected
    }

    // Get the selected text from the drop-down menu
    const selectedText = this.areaOfAdviceTarget.options[this.areaOfAdviceTarget.selectedIndex].text

    // Check if the selected AreaOfAdvice is "Other" and show/hide the appropriate divs
    if (selectedText === 'Other') {
      this.strategySelectTarget.style.display = "none"
      this.strategyTextFieldTarget.style.display = "block"
    } else {
      this.strategySelectTarget.style.display = "block"
      this.strategyTextFieldTarget.style.display = "none"

      // Fetch the strategies associated with the selected Area of Advice
      fetch(`/area_of_advices/${areaOfAdviceId}/strategies`)
        .then(response => response.json())
        .then(data => {
          // Log the data returned from the server
          console.log('Strategies data:', data)

          // Clear the strategy select box
          this.strategyTarget.innerHTML = ""

          var strategies = [];
          // Iterate through the strategies returned from the server
          data.forEach(strategy => {
            strategies.push(strategy.id)
            // Create a new option element for each strategy
            const option = document.createElement("option")
            option.value = strategy.id           // Set the value to the strategy's ID
            option.text = strategy.strategy      // Set the display text to the strategy's name
            this.strategyTarget.add(option)      // Add the option to the strategy select box
          })

          // Get the persisted strategy ID from the Rails view
          const persistedStrategyId = Number(this.data.get("id"));

          // Check if strategy ID exists and if it's in the current strategies list
          // If so, then set the <select> value to the persisted strategy ID
          if (persistedStrategyId != 0 && strategies.includes(persistedStrategyId)) {
            this.strategyTarget.value = persistedStrategyId;
          }
        })
    }
  }

  // Method to reset the strategy selection
  resetStrategySelection() {
    // Clear the strategy select box
    this.strategyTarget.innerHTML = ""

    // Add an option that prompts the user to "Select Strategy"
    const promptOption = document.createElement("option")
    promptOption.value = ""               // Set an empty value for the prompt option
    promptOption.text = "Select Strategy" // Set the display text to "Select Strategy"
    this.strategyTarget.add(promptOption) // Add the option to the strategy select box

    // Reset the visibility of strategySelectTarget and strategyTextFieldTarget
    this.strategySelectTarget.style.display = "block"
    this.strategyTextFieldTarget.style.display = "none"
  }
}

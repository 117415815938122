import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="expand-collapse-sidebar"
export default class extends Controller {
  // Declare the targets that we want to use in this controller
  static targets = ["initialMeeting", "research", "recommendations", "adviceDocument", "prepareForms", "advicePresentation", "authorityToProceed", "implementation"];

  connect() {
    console.log("ExpandCollapseSidebar connected!");
    // Load from local storage the state of the sidebar
    this.loadInitialMeeting();
    this.loadResearch();
    this.loadRecommendations();
    this.loadAdviceDocument();
    this.loadPrepareForms();
    this.loadAdvicePresentation();
    this.loadAuthorityToProceed();
    this.loadImplementation();

    // Auto expand the sidebar section when navigating to a page from the tasks index view 
    this.autoExpandSidebarSection();
  }

  autoExpandSidebarSection() {
    const sidebar = document.querySelector('.sidebar');
    const section = sidebar ? sidebar.getAttribute('data-sidebar-section') : null;

    if (section === "initialMeeting") {
      // Show the initial meeting
      this.initialMeetingTarget.classList.remove("hidden");
      localStorage.setItem("sidebarExpandedInitialMeeting", "true");
      // Hide the research
      this.researchTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedResearch", "false");
      // Hide the recommendations
      this.recommendationsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedRecommendations", "false");
      // Hide the advice document
      this.adviceDocumentTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdviceDocument", "false");
      // Hide the prepare forms
      this.prepareFormsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedPrepareForms", "false");
      // Hide the advice presentation
      this.advicePresentationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdvicePresentation", "false");
      // Hide the authority to proceed
      this.authorityToProceedTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAuthorityToProceed", "false");
      // Hide the implementation
      this.implementationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedImplementation", "false");

    } else if (section === "research") {
      this.researchTarget.classList.remove("hidden");
      localStorage.setItem("sidebarExpandedResearch", "true");
      // Hide the initial meeting
      this.initialMeetingTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedInitialMeeting", "false");
      // Show the research
      this.researchTarget.classList.remove("hidden");
      localStorage.setItem("sidebarExpandedResearch", "true");
      // Hide the recommendations
      this.recommendationsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedRecommendations", "false");
      // Hide the advice document
      this.adviceDocumentTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdviceDocument", "false");
      // Hide the prepare forms
      this.prepareFormsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedPrepareForms", "false");
      // Hide the advice presentation
      this.advicePresentationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdvicePresentation", "false");
      // Hide the authority to proceed
      this.authorityToProceedTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAuthorityToProceed", "false");
      // Hide the implementation
      this.implementationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedImplementation", "false");

    } else if (section === "recommendations") {
      this.recommendationsTarget.classList.remove("hidden");
      localStorage.setItem("sidebarExpandedRecommendations", "true");
      // Hide the initial meeting
      this.initialMeetingTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedInitialMeeting", "false");
      // Hide the research
      this.researchTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedResearch", "false");
      // Show the recommendations
      this.recommendationsTarget.classList.remove("hidden");
      localStorage.setItem("sidebarExpandedRecommendations", "true");
      // Hide the advice document
      this.adviceDocumentTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdviceDocument", "false");
      // Hide the prepare forms
      this.prepareFormsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedPrepareForms", "false");
      // Hide the advice presentation
      this.advicePresentationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdvicePresentation", "false");
      // Hide the authority to proceed
      this.authorityToProceedTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAuthorityToProceed", "false");
      // Hide the implementation
      this.implementationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedImplementation", "false");

    } else if (section === "adviceDocument") {
      // Hide the initial meeting
      this.initialMeetingTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedInitialMeeting", "false");
      // Hide the research
      this.researchTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedResearch", "false");
      // Hide the recommendations
      this.recommendationsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedRecommendations", "false");
      // Show the advice document
      this.adviceDocumentTarget.classList.remove("hidden");
      localStorage.setItem("sidebarExpandedAdviceDocument", "true");
      // Hide the prepare forms
      this.prepareFormsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedPrepareForms", "false");
      // Hide the advice presentation
      this.advicePresentationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdvicePresentation", "false");
      // Hide the authority to proceed
      this.authorityToProceedTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAuthorityToProceed", "false");
      // Hide the implementation
      this.implementationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedImplementation", "false");

    } else if (section === "prepareForms") {
      // Hide the initial meeting
      this.initialMeetingTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedInitialMeeting", "false");
      // Hide the research
      this.researchTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedResearch", "false");
      // Hide the recommendations
      this.recommendationsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedRecommendations", "false");
      // Hide the advice document
      this.adviceDocumentTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdviceDocument", "false");
      // Show the prepare forms
      this.prepareFormsTarget.classList.remove("hidden");
      localStorage.setItem("sidebarExpandedPrepareForms", "true");
      // Hide the advice presentation
      this.advicePresentationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdvicePresentation", "false");
      // Hide the authority to proceed
      this.authorityToProceedTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAuthorityToProceed", "false");
      // Hide the implementation
      this.implementationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedImplementation", "false");

    } else if (section === "advicePresentation") {
      // Hide the initial meeting
      this.initialMeetingTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedInitialMeeting", "false");
      // Hide the research
      this.researchTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedResearch", "false");
      // Hide the recommendations
      this.recommendationsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedRecommendations", "false");
      // Hide the advice document
      this.adviceDocumentTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdviceDocument", "false");
      // Hide the prepare forms
      this.prepareFormsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedPrepareForms", "false");
      // Show the advice presentation
      this.advicePresentationTarget.classList.remove("hidden");
      localStorage.setItem("sidebarExpandedAdvicePresentation", "true");
      // Hide the authority to proceed
      this.authorityToProceedTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAuthorityToProceed", "false");
      // Hide the implementation
      this.implementationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedImplementation", "false");

    } else if (section === "authorityToProceed") {
      // Hide the initial meeting
      this.initialMeetingTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedInitialMeeting", "false");
      // Hide the research
      this.researchTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedResearch", "false");
      // Hide the recommendations
      this.recommendationsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedRecommendations", "false");
      // Hide the advice document
      this.adviceDocumentTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdviceDocument", "false");
      // Hide the prepare forms
      this.prepareFormsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedPrepareForms", "false");
      // Hide the advice presentation
      this.advicePresentationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdvicePresentation", "false");
      // Show the authority to proceed
      this.authorityToProceedTarget.classList.remove("hidden");
      localStorage.setItem("sidebarExpandedAuthorityToProceed", "true");
      // Hide the implementation
      this.implementationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedImplementation", "false");

    } else if (section === "implementation") {
      // Hide the initial meeting
      this.initialMeetingTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedInitialMeeting", "false");
      // Hide the research
      this.researchTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedResearch", "false");
      // Hide the recommendations
      this.recommendationsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedRecommendations", "false");
      // Hide the advice document
      this.adviceDocumentTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdviceDocument", "false");
      // Hide the prepare forms
      this.prepareFormsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedPrepareForms", "false");
      // Hide the advice presentation
      this.advicePresentationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdvicePresentation", "false");
      // Hide the authority to proceed
      this.authorityToProceedTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAuthorityToProceed", "false");
      // Show the implementation
      this.implementationTarget.classList.remove("hidden");
      localStorage.setItem("sidebarExpandedImplementation", "true");

    } else if (section === "file_notes" || section === "details" || section === "exceptions" || section === "report") {
      // Hide the initial meeting
      this.initialMeetingTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedInitialMeeting", "false");
      // Hide the research
      this.researchTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedResearch", "false");
      // Hide the recommendations
      this.recommendationsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedRecommendations", "false");
      // Hide the advice document
      this.adviceDocumentTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdviceDocument", "false");
      // Hide the prepare forms
      this.prepareFormsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedPrepareForms", "false");
      // Hide the advice presentation
      this.advicePresentationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdvicePresentation", "false");
      // Hide the authority to proceed
      this.authorityToProceedTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAuthorityToProceed", "false");
      // Hide the implementation
      this.implementationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedImplementation", "false");
    }
  }

  toggleInitialMeeting(event) {
    const isHidden = this.initialMeetingTarget.classList.contains("hidden");

    if (isHidden) {
      // Show the initial meeting
      this.initialMeetingTarget.classList.remove("hidden");
      localStorage.setItem("sidebarExpandedInitialMeeting", "true");
      // Hide the research
      this.researchTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedResearch", "false");
      // Hide the recommendations
      this.recommendationsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedRecommendations", "false");
      // Hide the advice document
      this.adviceDocumentTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdviceDocument", "false");
      // Hide the prepare forms
      this.prepareFormsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedPrepareForms", "false");
      // Hide the advice presentation
      this.advicePresentationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdvicePresentation", "false");
      // Hide the authority to proceed
      this.authorityToProceedTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAuthorityToProceed", "false");
      // Hide the implementation
      this.implementationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedImplementation", "false");
    } else {
      // Hide the initial meeting
      this.initialMeetingTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedInitialMeeting", "false");
    }
  }

  loadInitialMeeting() {
    // Load from local storage the state of the sidebar
    const sidebarExpandedInitialMeeting = localStorage.getItem("sidebarExpandedInitialMeeting");

    if (sidebarExpandedInitialMeeting === "true") {
      this.initialMeetingTarget.classList.remove("hidden");
    } else {
      this.initialMeetingTarget.classList.add("hidden");
    }
  }

  toggleResearch(event) {
    const isHidden = this.researchTarget.classList.contains("hidden");

    if (isHidden) {
      // Hide the initial meeting
      this.initialMeetingTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedInitialMeeting", "false");
      // Show the research
      this.researchTarget.classList.remove("hidden");
      localStorage.setItem("sidebarExpandedResearch", "true");
      // Hide the recommendations
      this.recommendationsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedRecommendations", "false");
      // Hide the advice document
      this.adviceDocumentTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdviceDocument", "false");
      // Hide the prepare forms
      this.prepareFormsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedPrepareForms", "false");
      // Hide the advice presentation
      this.advicePresentationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdvicePresentation", "false");
      // Hide the authority to proceed
      this.authorityToProceedTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAuthorityToProceed", "false");
      // Hide the implementation
      this.implementationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedImplementation", "false");
    } else {
      // Hide the research
      this.researchTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedResearch", "false");
    }
  }

  loadResearch() {
    // Load from local storage the state of the sidebar
    const sidebarExpandedResearch = localStorage.getItem("sidebarExpandedResearch");

    if (sidebarExpandedResearch === "true") {
      this.researchTarget.classList.remove("hidden");
    } else {
      this.researchTarget.classList.add("hidden");
    }
  }

  toggleRecommendations(event) {
    const isHidden = this.recommendationsTarget.classList.contains("hidden");

    if (isHidden) {
      // Hide the initial meeting
      this.initialMeetingTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedInitialMeeting", "false");
      // Hide the research
      this.researchTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedResearch", "false");
      // Show the recommendations
      this.recommendationsTarget.classList.remove("hidden");
      localStorage.setItem("sidebarExpandedRecommendations", "true");
      // Hide the advice document
      this.adviceDocumentTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdviceDocument", "false");
      // Hide the prepare forms
      this.prepareFormsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedPrepareForms", "false");
      // Hide the advice presentation
      this.advicePresentationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdvicePresentation", "false");
      // Hide the authority to proceed
      this.authorityToProceedTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAuthorityToProceed", "false");
      // Hide the implementation
      this.implementationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedImplementation", "false");
    } else {
      // Hide the recommendations
      this.recommendationsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedRecommendations", "false");
    }
  }

  loadRecommendations() {
    // Load from local storage the state of the sidebar
    const sidebarExpandedRecommendations = localStorage.getItem("sidebarExpandedRecommendations");

    if (sidebarExpandedRecommendations === "true") {
      this.recommendationsTarget.classList.remove("hidden");
    } else {
      this.recommendationsTarget.classList.add("hidden");
    }
  }

  toggleAdviceDocument(event) {
    const isHidden = this.adviceDocumentTarget.classList.contains("hidden");

    if (isHidden) {
      // Hide the initial meeting
      this.initialMeetingTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedInitialMeeting", "false");
      // Hide the research
      this.researchTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedResearch", "false");
      // Hide the recommendations
      this.recommendationsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedRecommendations", "false");
      // Show the advice document
      this.adviceDocumentTarget.classList.remove("hidden");
      localStorage.setItem("sidebarExpandedAdviceDocument", "true");
      // Hide the prepare forms
      this.prepareFormsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedPrepareForms", "false");
      // Hide the advice presentation
      this.advicePresentationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdvicePresentation", "false");
      // Hide the authority to proceed
      this.authorityToProceedTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAuthorityToProceed", "false");
      // Hide the implementation
      this.implementationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedImplementation", "false");
    } else {
      // Hide the advice document
      this.adviceDocumentTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdviceDocument", "false");
    }
  }

  loadAdviceDocument() {
    // Load from local storage the state of the sidebar
    const sidebarExpandedAdviceDocument = localStorage.getItem("sidebarExpandedAdviceDocument");

    if (sidebarExpandedAdviceDocument === "true") {
      this.adviceDocumentTarget.classList.remove("hidden");
    } else {
      this.adviceDocumentTarget.classList.add("hidden");
    }
  }

  togglePrepareForms(event) {
    const isHidden = this.prepareFormsTarget.classList.contains("hidden");

    if (isHidden) {
      // Hide the initial meeting
      this.initialMeetingTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedInitialMeeting", "false");
      // Hide the research
      this.researchTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedResearch", "false");
      // Hide the recommendations
      this.recommendationsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedRecommendations", "false");
      // Hide the advice document
      this.adviceDocumentTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdviceDocument", "false");
      // Show the prepare forms
      this.prepareFormsTarget.classList.remove("hidden");
      localStorage.setItem("sidebarExpandedPrepareForms", "true");
      // Hide the advice presentation
      this.advicePresentationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdvicePresentation", "false");
      // Hide the authority to proceed
      this.authorityToProceedTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAuthorityToProceed", "false");
      // Hide the implementation
      this.implementationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedImplementation", "false");
    } else {
      // Hide the prepare forms
      this.prepareFormsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedPrepareForms", "false");
    }
  }

  loadPrepareForms() {
    // Load from local storage the state of the sidebar
    const sidebarExpandedPrepareForms = localStorage.getItem("sidebarExpandedPrepareForms");

    if (sidebarExpandedPrepareForms === "true") {
      this.prepareFormsTarget.classList.remove("hidden");
    } else {
      this.prepareFormsTarget.classList.add("hidden");
    }
  }

  toggleAdvicePresentation(event) {
    const isHidden = this.advicePresentationTarget.classList.contains("hidden");

    if (isHidden) {
      // Hide the initial meeting
      this.initialMeetingTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedInitialMeeting", "false");
      // Hide the research
      this.researchTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedResearch", "false");
      // Hide the recommendations
      this.recommendationsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedRecommendations", "false");
      // Hide the advice document
      this.adviceDocumentTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdviceDocument", "false");
      // Hide the prepare forms
      this.prepareFormsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedPrepareForms", "false");
      // Show the advice presentation
      this.advicePresentationTarget.classList.remove("hidden");
      localStorage.setItem("sidebarExpandedAdvicePresentation", "true");
      // Hide the authority to proceed
      this.authorityToProceedTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAuthorityToProceed", "false");
      // Hide the implementation
      this.implementationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedImplementation", "false");
    } else {
      // Hide the advice presentation
      this.advicePresentationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdvicePresentation", "false");
    }
  }

  loadAdvicePresentation() {
    // Load from local storage the state of the sidebar
    const sidebarExpandedAdvicePresentation = localStorage.getItem("sidebarExpandedAdvicePresentation");

    if (sidebarExpandedAdvicePresentation === "true") {
      this.advicePresentationTarget.classList.remove("hidden");
    } else {
      this.advicePresentationTarget.classList.add("hidden");
    }
  }

  toggleAuthorityToProceed(event) {
    const isHidden = this.authorityToProceedTarget.classList.contains("hidden");

    if (isHidden) {
      // Hide the initial meeting
      this.initialMeetingTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedInitialMeeting", "false");
      // Hide the research
      this.researchTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedResearch", "false");
      // Hide the recommendations
      this.recommendationsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedRecommendations", "false");
      // Hide the advice document
      this.adviceDocumentTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdviceDocument", "false");
      // Hide the prepare forms
      this.prepareFormsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedPrepareForms", "false");
      // Hide the advice presentation
      this.advicePresentationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdvicePresentation", "false");
      // Show the authority to proceed
      this.authorityToProceedTarget.classList.remove("hidden");
      localStorage.setItem("sidebarExpandedAuthorityToProceed", "true");
      // Hide the implementation
      this.implementationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedImplementation", "false");
    } else {
      // Hide the authority to proceed
      this.authorityToProceedTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAuthorityToProceed", "false");
    }
  }

  loadAuthorityToProceed() {
    // Load from local storage the state of the sidebar
    const sidebarExpandedAuthorityToProceed = localStorage.getItem("sidebarExpandedAuthorityToProceed");

    if (sidebarExpandedAuthorityToProceed === "true") {
      this.authorityToProceedTarget.classList.remove("hidden");
    } else {
      this.authorityToProceedTarget.classList.add("hidden");
    }
  }

  toggleImplementation(event) {
    const isHidden = this.implementationTarget.classList.contains("hidden");

    if (isHidden) {
      // Hide the initial meeting
      this.initialMeetingTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedInitialMeeting", "false");
      // Hide the research
      this.researchTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedResearch", "false");
      // Hide the recommendations
      this.recommendationsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedRecommendations", "false");
      // Hide the advice document
      this.adviceDocumentTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdviceDocument", "false");
      // Hide the prepare forms
      this.prepareFormsTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedPrepareForms", "false");
      // Hide the advice presentation
      this.advicePresentationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAdvicePresentation", "false");
      // Hide the authority to proceed
      this.authorityToProceedTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedAuthorityToProceed", "false");
      // Show the implementation
      this.implementationTarget.classList.remove("hidden");
      localStorage.setItem("sidebarExpandedImplementation", "true");
    } else {
      // Hide the implementation
      this.implementationTarget.classList.add("hidden");
      localStorage.setItem("sidebarExpandedImplementation", "false");
    }
  }

  loadImplementation() {
    // Load from local storage the state of the sidebar
    const sidebarExpandedImplementation = localStorage.getItem("sidebarExpandedImplementation");

    if (sidebarExpandedImplementation === "true") {
      this.implementationTarget.classList.remove("hidden");
    } else {
      this.implementationTarget.classList.add("hidden");
    }
  }

}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-hide-new-strategy"
export default class extends Controller {
  static targets = ["product_recommendation_required", 
                    "WhyProductRecommendationRequired",

                    "implementation_required",
                    "Implementation",

                    "recommendationAccepted",
                    "recommendationAcceptedDetails"
                  ]

  connect() {
    console.log("ShowHideNewStrategy connected!") 

    this.handleCheckboxChange();
    this.element.addEventListener("change", () => this.handleCheckboxChange());

    this.handleImplementationCheckboxChange();
    this.element.addEventListener("change", () => this.handleImplementationCheckboxChange());

    this.handleRecommendationAcceptedChange();
    this.element.addEventListener("change", () => this.handleRecommendationAcceptedChange());    
  }

  handleCheckboxChange() {
    if (this.hasProduct_recommendation_requiredTarget && this.hasWhyProductRecommendationRequiredTarget) {
      const productRecommendationRequiredValue = this.product_recommendation_requiredTarget.value;

      console.log(`Product recommendation required: ${productRecommendationRequiredValue}`);

      // Perform logic based on the checkbox values
      if (productRecommendationRequiredValue === "true") {
        // Show WhyProductRecommendationRequired
        this.WhyProductRecommendationRequiredTarget.classList.remove("hidden");
      } else {
        // Hide WhyProductRecommendationRequired
        this.WhyProductRecommendationRequiredTarget.classList.add("hidden");
      }
    }
  }

  handleImplementationCheckboxChange() {
    if (this.hasImplementation_requiredTarget && this.hasImplementationTarget) {
      const implementation_requiredValue = this.implementation_requiredTarget.value;

      console.log(`Implementation Required: ${implementation_requiredValue}`);

      // Perform logic based on the checkbox values
      if (implementation_requiredValue === "true") {
        // Show Implementation
        this.ImplementationTarget.classList.remove("hidden");
      } else {
        // Hide Implementation
        this.ImplementationTarget.classList.add("hidden");
      }
    }
  } 

  handleRecommendationAcceptedChange() {
    if (this.hasRecommendationAcceptedTarget && this.hasRecommendationAcceptedDetailsTarget) {
      const recommendationAcceptedValue = this.recommendationAcceptedTarget.value;

      console.log(`Recommendation Accepted: ${recommendationAcceptedValue}`);

      // Perform logic based on the checkbox values
      if (recommendationAcceptedValue === 'yes_with_changes') {
        // Show RecommendationAcceptedDetails
        this.recommendationAcceptedDetailsTarget.classList.remove("hidden");
      } else {
        // Hide RecommendationAcceptedDetails
        this.recommendationAcceptedDetailsTarget.classList.add("hidden");
      }
    }
  }
}

export function autoResizeTextarea(textarea) {
  textarea.style.height = 'auto';
  textarea.style.height = `${Math.max(textarea.scrollHeight + 20, 100)}px`;
}

export function initializeTextareas() {
  const textareas = document.querySelectorAll('textarea');
  textareas.forEach(textarea => {
    textarea.style.minHeight = '100px';
    autoResizeTextarea(textarea);
    textarea.addEventListener('input', function() {
      autoResizeTextarea(this);
    });
  });
}

export function setupTextareaAutoResize() {
  // Run on initial page load
  document.addEventListener('DOMContentLoaded', initializeTextareas);

  // Run after Turbo drive navigation
  document.addEventListener('turbo:load', initializeTextareas);

  // Run after Turbo frame updates
  document.addEventListener('turbo:frame-load', initializeTextareas);

  // Optionally, run after Turbo stream updates
  document.addEventListener('turbo:render', initializeTextareas);
}
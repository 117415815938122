import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mobile-menu"
export default class extends Controller {
  static targets = ["open", "close"]

  toggle() {
    this.closeTarget.classList.toggle('hidden')
    this.openTarget.classList.toggle('hidden')
  }
}

function setTurboHeadersWithNonce(event) {
  // Turbo Drive does not send a referrer like turbolinks used to, so let's simulate it here
  event.detail.fetchOptions.headers['Turbo-Referrer'] = window.location.href;
  event.detail.fetchOptions.headers['X-Turbo-Nonce'] = document.querySelector("meta[name='csp-nonce']").content;
}

function applyNonceToScriptTags() {
  let scriptTagsToAddNonces = document.querySelectorAll("script[nonce]");
  for (var element of scriptTagsToAddNonces) {
    element.setAttribute('nonce', element.nonce);
  }
}

function addSubmitOnChangeListeners() {
  const elements = document.querySelectorAll('[data-submit-on-change="true"]');

  elements.forEach(element => {
    element.addEventListener("change", function() {
      this.form.requestSubmit();
    });
  });
}

// Listen for both DOMContentLoaded
document.addEventListener('DOMContentLoaded', addSubmitOnChangeListeners);
// Listen for Turbo before cache
document.addEventListener('turbo:before-cache', applyNonceToScriptTags);
// Listen for Turbo before fetch request
document.addEventListener('turbo:before-fetch-request', setTurboHeadersWithNonce);
// Listen for Turbo render to re-attach event listeners
document.addEventListener('turbo:render', addSubmitOnChangeListeners);
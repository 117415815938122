import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-hide-initial-meeting"
export default class extends Controller {
  static targets = ["investing_in_scope", "insurance_in_scope", "investingDetails", "insuranceDetails"]

  connect() {
    console.log("ShowHideInitialMeeting controller connected!");  

    this.handleScopeChange();
    this.element.addEventListener("change", () => this.handleScopeChange());
  }

  handleScopeChange() {
    const investingInScopeValue = this.investing_in_scopeTarget.value;
    console.log(`Investing In Scope: ${investingInScopeValue}`);

    const insuranceInScopeValue = this.insurance_in_scopeTarget.value;
    console.log(`Insurance In Scope: ${insuranceInScopeValue}`);

    // Perform logic based on the checkbox values
    if (investingInScopeValue === 'true') {
      // Show investing details
      this.investingDetailsTarget.classList.remove("hidden");
    } else {
      // Hide investing details
      this.investingDetailsTarget.classList.add("hidden");
    }

    if (insuranceInScopeValue === 'true') {
      // Show insurance details
      this.insuranceDetailsTarget.classList.remove("hidden");
    } else {
      // Hide insurance details
      this.insuranceDetailsTarget.classList.add("hidden");
    }
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-hide-goals"
export default class extends Controller {
  static targets = ["is_this_goal_included_in_the_advice", "WhyIsntThisGoalIncluded"]

  connect() {
    console.log("ShowHideGoals controller connected!");

    this.handleGoalIncludedChange();
    this.element.addEventListener("change", () => this.handleGoalIncludedChange());
  }

  handleGoalIncludedChange() {
    const isGoalIncludedValue = this.is_this_goal_included_in_the_adviceTarget.value;

    // Perform logic based on the checkbox values
    if (isGoalIncludedValue === 'false') {
      // Show WhyIsntThisGoalIncluded
      this.WhyIsntThisGoalIncludedTarget.classList.remove("hidden");
    } else {
      // Hide WhyIsntThisGoalIncluded
      this.WhyIsntThisGoalIncludedTarget.classList.add("hidden");
    }
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-hide-time-critical-advice"
export default class extends Controller {
  static targets = ["time_critical_advice", "TimeCriticalAdviceDetails"]

  connect() {
    console.log("ShowHideTimeCriticalAdvice connected!")

    this.handleCheckboxChange();
    this.element.addEventListener("change", () => this.handleCheckboxChange());
  }

  handleCheckboxChange() {
    const tcaValue = this.time_critical_adviceTarget.value;

    console.log(`Time Critical Advice: ${tcaValue}`);

    // Perform logic based on the checkbox values
    if (tcaValue === 'true') {
      // Show
      this.TimeCriticalAdviceDetailsTarget.classList.remove("hidden");
    } else {
      // Hide
      this.TimeCriticalAdviceDetailsTarget.classList.add("hidden");
    }
  }

}

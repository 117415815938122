import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-hide-advice-document-before-presenting"
export default class extends Controller {
  static targets = ["advice_document_before_presenting",
                    "AdviceDocumentBeforePresentingDetails"]

  connect() {
    console.log("ShowHideAdviceDocumentBeforePresenting connected!")

    this.handleCheckboxChange();
    this.element.addEventListener("change", () => this.handleCheckboxChange());
  }

  handleCheckboxChange() {
    const adbaValue = this.advice_document_before_presentingTarget.value;

    console.log(`Advice Document Before Presenting: ${adbaValue}`);

    // Perform logic based on the checkbox values
    if (adbaValue === 'true') {
      // Show
      this.AdviceDocumentBeforePresentingDetailsTarget.classList.remove("hidden");
    } else {
      // Hide
      this.AdviceDocumentBeforePresentingDetailsTarget.classList.add("hidden");
    }
  }
}

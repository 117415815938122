import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-hide-conflict"
export default class extends Controller {
  static targets = ["conflict_of_interest", "ConflictExplanation"]

  connect() {
    console.log("ShowHideConflict connected!")

    this.handleCheckboxChange();
    this.element.addEventListener("change", () => this.handleCheckboxChange());
  }

  handleCheckboxChange() {
    const conflictValue = this.conflict_of_interestTarget.value;

    console.log(`Conflict: ${conflictValue}`);

    // Perform logic based on the checkbox values
    if (conflictValue === "yes") {
      // Show ConflictExplanation
      this.ConflictExplanationTarget.classList.remove("hidden");
    } else {
      // Hide ConflictExplanation
      this.ConflictExplanationTarget.classList.add("hidden");
    }
  }

}
